import { whatsappLink } from '@global/utils/url/create-whatsapp-url';
import { Body, Col, Grid, H1, LargeSeparator, Row } from '@web/atomic';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import LargeSeparatorRow from '../components/atm.large-separator-row/large-separator-row.component';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import IndexLayout from '../components/org.layout/layout.component';
import { EmailContacts } from '../domain/contacts';
import { HcpFAQuestionKey } from '../modules/about/faq-utils';
import FaqComponent from '../modules/about/faq.component';
import { HcpFAQuestions } from '../modules/about/hcp-faq.utils';
import { ProfileSelectionSection } from '../modules/home/components/profile-selection-section.component';
import { PageProps } from '../utils/local-types';

const FAQForHcp: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout location={props.location} whatsappKind={'sales'}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Perguntas frequentes de profissionais da saúde',
          description: 'Saiba mais sobre terapia cognitivo-comportamental para insônia e o programa da Vigilantes do Sono',
        }}
        jsonld={{
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: [
            HcpFAQuestions.map((question) => ({
              '@type': 'Question',
              name: question.title,
              acceptedAnswer: {
                '@type': 'Answer',
                text: question.seoDescription,
              },
            })),
          ],
        }}
      />

      <Grid>
        {/* TOP SECTION */}
        <Row mt center="xs">
          <Col xs={12} md={7}>
            <H1>Perguntas frequentes de profissionais da saúde</H1>
            {HcpFAQuestions.find((q) => q.key === HcpFAQuestionKey.TelemonitorRegulations)?.content}
            <Body>
              Para quaisquer dúvidas ou dificuldades que você ou seu paciente vierem a ter, podem entrar em contato com nosso suporte no
              número de{' '}
              <a href={whatsappLink('sales')} rel="noreferrer" target="_blank">
                {' '}
                WhatsApp: +55 (11) 91157-9831{' '}
              </a>{' '}
              , no link <a href="https://vigilantesdosono.com/whatsapp-profissionais">vigilantesdosono.com/whatsapp-profissionais</a> ou no
              e-mail: <a href={`mailto:${EmailContacts.Support}`}>{EmailContacts.Support}</a>. <br /> Dentro do app no canto superior
              direito, temos um botão de <strong>“Suporte”</strong> que enviará seu paciente direto para o contato. Ou ainda na tela de{' '}
              <strong>“Configurações”</strong> em Suporte.
            </Body>
            <LargeSeparator />
          </Col>
        </Row>

        <Row mb center="xs">
          <Col xs={12} md={7}>
            <FaqComponent allQuestions={HcpFAQuestions} />
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>

      <ProfileSelectionSection />
    </IndexLayout>
  );
};

export default FAQForHcp;

export const query = graphql`
  query FAQForHcp {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
